import React, { useEffect, useState } from "react"
import { Button, Card } from "antd"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Prismic from "prismic-javascript"

const prismicURL = "https://bijouterie-lunas.cdn.prismic.io/api/v2"

const { Meta } = Card;



const Links = () => {
  const [ links, setLinks ] = useState([]);
  const [ categories, setCategories ] = useState([]);

  useEffect(() => {
    const items = []
    const cats = [];

    Prismic.getApi(prismicURL).then((api) => {
      return api.query(Prismic.Predicates.at('document.type', 'partners'))
    })
      .then(response => {
        response.results.forEach(val => {
          let index = null;

          if (cats.includes(val.data.categorie[0].text)) {
            index = cats.findIndex(i => i === val.data.categorie[0].text);
          }
          else {
            cats.push(val.data.categorie[0].text);
            index = cats.length - 1;
          }

          let item = {
            name: val.data.name[0].text,
            description: val.data.description[0].text,
            url: val.data.url.url,
            image: val.data.image.url,
            categorie: index
          };

          items.push(item);
        });

        setLinks(items);
        setCategories(cats);
      })
      .catch(err => {
        console.log(err);
      })
  }, []);

  return (
    <Layout>
      <SEO title="Les Bonnes adresses de confiances" />

      <div className="slideshow">
        <div className="usp">
          <div  className="usp-txt-header" style={{fontSize: '5em'}}>Les Bonnes adresses de confiances</div>
        </div>
      </div>
      <div className="border-gold-bottom"></div>


      <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Les Bonnes adresses de confiances</h2>

      <section>
        <p className="p-font">
          Nous sommes commerçants mais surtout des artisans.
          le savoir-faire se perd petit à petit et aujourd’hui il est difficile de trouver de petites structures qui proposent un service sûr et compétent.
        </p>
        <p className="p-font">
          Nous partageons donc ici avec vous quelques bonnes adresses de qualité et de confiances.
        </p>
      </section>

      {categories.map((cat, index) => (
        <div key={index}>
          <div className="separator" />

          <section>
            <h4 className="h4-border">{cat}</h4>

            <div className="section-flex-05">
              {links.map(link => (
                <Card key={link.url} title={<strong>{link.name}</strong>} bordered={true} className="links-card" style={{ textAlign: 'center' }}>
                  <div><img src={link.image} alt={link.name} style={{ maxWidth: '60%'}} /></div>
                  <p>{link.description}</p>
                  <Button href={link.url} target="_blank" type="primary">Visiter leur site</Button>
                </Card>
              ))}

            </div>
          </section>
        </div>
      ))}
    </Layout>
  )
}

export default Links
